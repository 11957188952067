// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './LineDiagram.module.scss';

// 3RD PARTY
import classNames from 'classnames';

// CONFIG & DATA
const Config = {
  size: 15,
  dotRadius: 1.5,
  defaultRenderMin: 1,
  defaultRenderMax: 10,
  defaultStyle: {
    score: 'primary',
    score2: 'yellow',
    range: 'primary',
  },
};


// COMPONENT: LineDiagram
const LineDiagram = (props) => {
  // PROPS
  const {
    score,
    score2,
    range,
    renderMin = Config.defaultRenderMin,
    renderMax = Config.defaultRenderMax,
    style,
    distribution,
  } = props;

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const styleInternal = { ...Config.defaultStyle, ...style };
  const segments = Math.abs(renderMax - renderMin);
  const step = 100 / segments;

  let rangeStart;
  let rangeEnd;
  if (range?.length) {
    rangeStart = Math.max(range[0], renderMin);
    rangeEnd = Math.min(range[1], renderMax);
  }


  // RENDER: LineDiagram
  return (
    <div
      className={styles.lineDiagram}
      style={{
        padding: `0 ${(Config.size / 2) + 0.2}px`, // 0.2 - some extra space
        height: Config.size,
      }}
    >
      <svg height={Config.size}>
        { /* RANGES */ }
        { Boolean(range?.length) && (
          <rect
            x={`${(rangeStart - renderMin) * step}%`}
            y={(Config.size - 1) / 2}
            width={`${(rangeEnd - rangeStart) * step}%`}
            height={1}
            rx={0.5}
            strokeWidth={Config.size}
            className={classNames(
              styles.range,
              styles[styleInternal.range],
            )}
          />
        ) }

        { /* GREY LINE */ }
        <rect
          y={Math.floor(Config.size / 2)}
          width='100%'
          height={1}
          className={styles.grey}
        />

        { /* GREY DOTS */ }
        { new Array(segments + 1).fill().map((_, i) => (
          <circle
            key={i} // eslint-disable-line react/no-array-index-key
            cx={`${i * step}%`}
            cy={Config.size / 2}
            r={Config.dotRadius}
            className={styles.grey}
          />
        )) }

        { /* SCORE MARKER 1 */ }
        { Number.isFinite(score) && (
          <circle
            cx={`${(score - renderMin) * step}%`}
            cy={Config.size / 2}
            r={Config.size / 2}
            data-test='LineDiagramDot'
            className={classNames(
              styles.marker,
              styles[styleInternal.score],
            )}
          />
        ) }

        { /* SCORE MARKER 2 */ }
        { Number.isFinite(score2) && (
          <circle
            cx={`${(score2 - renderMin) * step}%`}
            cy={Config.size / 2}
            r={Config.size / 2}
            data-test='LineDiagramDot2'
            className={classNames(
              styles.marker,
              styles[styleInternal.score2],
            )}
          />
        ) }

        { /* DISTRIBUTION MARKERS */ }
        { distribution?.map((marker, i) => {
          const markerStyle = marker.style ?? styleInternal.range;
          return (
            <circle
              key={i} // eslint-disable-line react/no-array-index-key
              cx={`${(marker.value - renderMin) * step}%`}
              cy={Config.size / 2}
              r={Config.size / 4}
              className={classNames(
                styles.marker,
                styles[markerStyle],
                { [styles.light]: markerStyle === styleInternal.range },
              )}
            />
          );
        }) }
      </svg>
    </div>
  );
};

export default LineDiagram;
